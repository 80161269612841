/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AmendmentCreatePayload,
  ContractActions,
  ContractAndAmendmentLegsFilteredUpdateParams,
  ContractAndAmendmentLegsFilteredUpdatePayload,
  ContractAndAmendmentLegsListParams,
  ContractAndAmendmentLegsLocationsListParams,
  ContractFieldType,
  ContractImportUpdatePayload,
  ContractsCreatePayload,
  ContractsListParams,
  ContractStatusType,
  ContractsUpdatePayload,
  EquipmentType,
  ExportListParams,
  LocationType,
  Modality,
  SearchContractCodesListParams,
  TerminusType,
  UnlocodeGroupType,
  UpdateAcceptedUpdatePayload,
} from "./api";
import { ContentType, HttpClient, RequestParams } from "./httpClient";

export class Contracts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Return the list of contracts and their amendments
   *
   * @tags Contract, Amendment
   * @name ContractsList
   * @summary List contracts
   * @request GET:/contracts
   * @secure
   */
  contractsList = (query: ContractsListParams, params: RequestParams = {}) =>
    this.request<
      {
        total: number;
        count: number;
        page: number;
        limit: number;
      } & {
        result?: {
          uid: string;
          hasValidationErrors: boolean;
          title: string;
          parentTenderUid?: string;
          parentContractUid: string | null;
          company?: {
            uid: string;
            name: string;
            /** @format date-time */
            deactivatedAt?: Date;
            /** @format date-time */
            archivedAt?: Date;
          };
          /** @format contract-status-type-enum */
          status: ContractStatusType;
          isAmendment: boolean;
          contractCode: string | null;
          contractFieldsLegs?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsCost?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsTime?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          amendments?: {
            title: string;
            uid: string;
            hasValidationErrors: boolean;
            parentContractUid?: string;
            contractCode: string | null;
            company?: {
              uid: string;
              name: string;
              /** @format date-time */
              deactivatedAt?: Date;
              /** @format date-time */
              archivedAt?: Date;
            };
            /** @format contract-status-type-enum */
            status: ContractStatusType;
            note?: string;
            contractFieldsLegs?: {
              name: string;
              displayName: string;
              isActive: boolean;
              isRequired: boolean;
              isConfigurable: boolean;
              /** @format contract-field-enum */
              fieldType: ContractFieldType;
              /** @format date-time */
              createdAt?: Date;
              /** @format date-time */
              updatedAt?: Date;
            }[];
            contractFieldsCost?: {
              name: string;
              displayName: string;
              isActive: boolean;
              isRequired: boolean;
              isConfigurable: boolean;
              /** @format contract-field-enum */
              fieldType: ContractFieldType;
              /** @format date-time */
              createdAt?: Date;
              /** @format date-time */
              updatedAt?: Date;
            }[];
            contractFieldsTime?: {
              name: string;
              displayName: string;
              isActive: boolean;
              isRequired: boolean;
              isConfigurable: boolean;
              /** @format contract-field-enum */
              fieldType: ContractFieldType;
              /** @format date-time */
              createdAt?: Date;
              /** @format date-time */
              updatedAt?: Date;
            }[];
            /** @format date-time */
            publishedAt?: Date;
            /** @format date-time */
            acceptedAt?: Date;
            /** @format date-time */
            activeAt: Date;
            /** @format date-time */
            expiredAt: Date;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
            links?: {
              /** @format contract-actions-enum */
              rel: ContractActions;
              uri?: string | null;
              disabled?: boolean;
            }[];
          }[];
          /** @format date-time */
          publishedAt?: Date;
          /** @format date-time */
          acceptedAt?: Date;
          /** @format date-time */
          activeAt: Date;
          /** @format date-time */
          expiredAt: Date;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
          links?: {
            /** @format contract-actions-enum */
            rel: ContractActions;
            uri?: string | null;
            disabled?: boolean;
          }[];
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Adds a contract, works only in portal mode
   *
   * @tags Contract
   * @name ContractsCreate
   * @summary Creates a new Contract
   * @request POST:/contracts
   * @secure
   */
  contractsCreate = (data: ContractsCreatePayload, params: RequestParams = {}) =>
    this.request<
      {
        uid: string;
        hasValidationErrors: boolean;
        title: string;
        parentTenderUid?: string;
        parentContractUid: string | null;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        isAmendment: boolean;
        contractCode: string | null;
        contractFieldsLegs?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsCost?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsTime?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        amendments?: {
          title: string;
          uid: string;
          hasValidationErrors: boolean;
          parentContractUid?: string;
          contractCode: string | null;
          company?: {
            uid: string;
            name: string;
            /** @format date-time */
            deactivatedAt?: Date;
            /** @format date-time */
            archivedAt?: Date;
          };
          /** @format contract-status-type-enum */
          status: ContractStatusType;
          note?: string;
          contractFieldsLegs?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsCost?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsTime?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          /** @format date-time */
          publishedAt?: Date;
          /** @format date-time */
          acceptedAt?: Date;
          /** @format date-time */
          activeAt: Date;
          /** @format date-time */
          expiredAt: Date;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
          links?: {
            /** @format contract-actions-enum */
            rel: ContractActions;
            uri?: string | null;
            disabled?: boolean;
          }[];
        }[];
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Return a single contract and its amendments
   *
   * @tags Contract
   * @name ContractsDetail
   * @summary Get a contract
   * @request GET:/contracts/{contractUid}
   * @secure
   */
  contractsDetail = (contractUid: string, params: RequestParams = {}) =>
    this.request<
      {
        uid: string;
        hasValidationErrors: boolean;
        title: string;
        parentTenderUid?: string;
        parentContractUid: string | null;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        isAmendment: boolean;
        contractCode: string | null;
        contractFieldsLegs?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsCost?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsTime?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        amendments?: {
          title: string;
          uid: string;
          hasValidationErrors: boolean;
          parentContractUid?: string;
          contractCode: string | null;
          company?: {
            uid: string;
            name: string;
            /** @format date-time */
            deactivatedAt?: Date;
            /** @format date-time */
            archivedAt?: Date;
          };
          /** @format contract-status-type-enum */
          status: ContractStatusType;
          note?: string;
          contractFieldsLegs?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsCost?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsTime?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          /** @format date-time */
          publishedAt?: Date;
          /** @format date-time */
          acceptedAt?: Date;
          /** @format date-time */
          activeAt: Date;
          /** @format date-time */
          expiredAt: Date;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
          links?: {
            /** @format contract-actions-enum */
            rel: ContractActions;
            uri?: string | null;
            disabled?: boolean;
          }[];
        }[];
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update custom contract for portal mode
   *
   * @tags Contract
   * @name ContractsUpdate
   * @summary Update custom contract
   * @request PUT:/contracts/{contractUid}
   * @secure
   */
  contractsUpdate = (contractUid: string, data: ContractsUpdatePayload, params: RequestParams = {}) =>
    this.request<
      {
        uid: string;
        hasValidationErrors: boolean;
        title: string;
        parentTenderUid?: string;
        parentContractUid: string | null;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        isAmendment: boolean;
        contractCode: string | null;
        contractFieldsLegs?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsCost?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsTime?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        amendments?: {
          title: string;
          uid: string;
          hasValidationErrors: boolean;
          parentContractUid?: string;
          contractCode: string | null;
          company?: {
            uid: string;
            name: string;
            /** @format date-time */
            deactivatedAt?: Date;
            /** @format date-time */
            archivedAt?: Date;
          };
          /** @format contract-status-type-enum */
          status: ContractStatusType;
          note?: string;
          contractFieldsLegs?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsCost?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsTime?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          /** @format date-time */
          publishedAt?: Date;
          /** @format date-time */
          acceptedAt?: Date;
          /** @format date-time */
          activeAt: Date;
          /** @format date-time */
          expiredAt: Date;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
          links?: {
            /** @format contract-actions-enum */
            rel: ContractActions;
            uri?: string | null;
            disabled?: boolean;
          }[];
        }[];
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Update the contract code for a contract
   *
   * @tags Contract
   * @name UpdateAcceptedUpdate
   * @summary Update contract code for a contract
   * @request PUT:/contracts/{contractUid}/updateAccepted
   * @secure
   */
  updateAcceptedUpdate = (contractUid: string, data: UpdateAcceptedUpdatePayload, params: RequestParams = {}) =>
    this.request<
      {
        uid: string;
        hasValidationErrors: boolean;
        title: string;
        parentTenderUid?: string;
        parentContractUid: string | null;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        isAmendment: boolean;
        contractCode: string | null;
        contractFieldsLegs?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsCost?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsTime?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        amendments?: {
          title: string;
          uid: string;
          hasValidationErrors: boolean;
          parentContractUid?: string;
          contractCode: string | null;
          company?: {
            uid: string;
            name: string;
            /** @format date-time */
            deactivatedAt?: Date;
            /** @format date-time */
            archivedAt?: Date;
          };
          /** @format contract-status-type-enum */
          status: ContractStatusType;
          note?: string;
          contractFieldsLegs?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsCost?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          contractFieldsTime?: {
            name: string;
            displayName: string;
            isActive: boolean;
            isRequired: boolean;
            isConfigurable: boolean;
            /** @format contract-field-enum */
            fieldType: ContractFieldType;
            /** @format date-time */
            createdAt?: Date;
            /** @format date-time */
            updatedAt?: Date;
          }[];
          /** @format date-time */
          publishedAt?: Date;
          /** @format date-time */
          acceptedAt?: Date;
          /** @format date-time */
          activeAt: Date;
          /** @format date-time */
          expiredAt: Date;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
          links?: {
            /** @format contract-actions-enum */
            rel: ContractActions;
            uri?: string | null;
            disabled?: boolean;
          }[];
        }[];
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/updateAccepted`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Updates the status of a custom contract to published and auto-approves it so rates are searchable
   *
   * @tags Contract
   * @name PublishUpdate
   * @summary Publish a custom contract
   * @request PUT:/contracts/{contractUid}/publish
   * @secure
   */
  publishUpdate = (contractUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/publish`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description Updates the status of an Custom Contract to unpublished and retracts the rates from the search legs
   *
   * @tags Contract
   * @name UnpublishUpdate
   * @summary Unpublish a custom contract
   * @request PUT:/contracts/{contractUid}/unpublish
   * @secure
   */
  unpublishUpdate = (contractUid: string, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/unpublish`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * @description Update custom contract legs through an imported Excel sheet
   *
   * @tags Contract
   * @name ContractImportUpdate
   * @summary Override custom contract with import
   * @request PUT:/contracts/{contractUid}/contractImport
   * @secure
   */
  contractImportUpdate = (contractUid: string, data: ContractImportUpdatePayload, params: RequestParams = {}) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/contractImport`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * @description Get status for contract import background jobs
   *
   * @tags Contract
   * @name ImportList
   * @summary Get status for contract import
   * @request GET:/contracts/{contractUid}/import
   * @secure
   */
  importList = (contractUid: string, params: RequestParams = {}) =>
    this.request<
      {
        progress?: number;
        hasValidationErrors?: boolean;
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/import`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get validation errors for contract import background jobs
   *
   * @tags Contract
   * @name ValidationErrorsList
   * @summary Get validation errors for contract import
   * @request GET:/contracts/{contractUid}/validationErrors
   * @secure
   */
  validationErrorsList = (contractUid: string, params: RequestParams = {}) =>
    this.request<
      {
        validationErrors?: {
          errorRow?: number | null;
          errorDescription?: string;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/validationErrors`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Return a list of simple contract code objects belonging to the contract's carrier
   *
   * @tags Contract
   * @name SearchContractCodesList
   * @summary Search contract codes
   * @request GET:/contracts/{contractUid}/searchContractCodes
   * @secure
   */
  searchContractCodesList = ({ contractUid, ...query }: SearchContractCodesListParams, params: RequestParams = {}) =>
    this.request<
      {
        result?: {
          contractCode: string;
          description?: string;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/searchContractCodes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Adds an amendment to a contract
   *
   * @tags Contract
   * @name AmendmentCreate
   * @summary Creates a new Amendment
   * @request POST:/contracts/{contractUid}/amendment
   * @secure
   */
  amendmentCreate = (contractUid: string, data: AmendmentCreatePayload, params: RequestParams = {}) =>
    this.request<
      {
        title: string;
        uid: string;
        hasValidationErrors: boolean;
        parentContractUid?: string;
        contractCode: string | null;
        company?: {
          uid: string;
          name: string;
          /** @format date-time */
          deactivatedAt?: Date;
          /** @format date-time */
          archivedAt?: Date;
        };
        /** @format contract-status-type-enum */
        status: ContractStatusType;
        note?: string;
        contractFieldsLegs?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsCost?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        contractFieldsTime?: {
          name: string;
          displayName: string;
          isActive: boolean;
          isRequired: boolean;
          isConfigurable: boolean;
          /** @format contract-field-enum */
          fieldType: ContractFieldType;
          /** @format date-time */
          createdAt?: Date;
          /** @format date-time */
          updatedAt?: Date;
        }[];
        /** @format date-time */
        publishedAt?: Date;
        /** @format date-time */
        acceptedAt?: Date;
        /** @format date-time */
        activeAt: Date;
        /** @format date-time */
        expiredAt: Date;
        /** @format date-time */
        createdAt: Date;
        /** @format date-time */
        updatedAt: Date;
        links?: {
          /** @format contract-actions-enum */
          rel: ContractActions;
          uri?: string | null;
          disabled?: boolean;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/amendment`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * @description Export a contract and its amendments with legs as an excel file
   *
   * @tags Contract
   * @name ExportList
   * @summary Export contract as excel
   * @request GET:/contracts/{contractUid}/export
   * @secure
   */
  exportList = ({ contractUid, ...query }: ExportListParams, params: RequestParams = {}) =>
    this.request<
      File,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/export`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * @description Return the list of contract legs
   *
   * @tags Contract
   * @name ContractAndAmendmentLegsList
   * @summary List contract legs
   * @request GET:/contracts/{contractUid}/contractAndAmendmentLegs
   * @secure
   */
  contractAndAmendmentLegsList = (
    { contractUid, ...query }: ContractAndAmendmentLegsListParams,
    params: RequestParams = {},
  ) =>
    this.request<
      {
        total: number;
        count: number;
        page: number;
        limit: number;
      } & {
        result?: (({
          originCountry: string;
          originPlace: string;
          destinationCountry: string;
          destinationPlace: string;
          /** @format equipment-type-enum */
          equipmentType: EquipmentType;
          /** @format modality-enum */
          modality: Modality;
          origin?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
          destination?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
        } & {
          templateLegId: string;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          updatedAt: Date;
        }) & {
          groupUuid: string;
          legUuid: string;
          contractCode?: string | null;
          costBaseRate: number;
          costBaseRateCurrency: string;
          timeTransit: number;
          declined: boolean;
          /** @format date-time */
          createdAt: Date;
          /** @format date-time */
          activeAt: Date;
          /** @format date-time */
          expiredAt: Date;
          tradeLaneOrigin?: {
            id: string;
            name: string;
            /** @format unlocodegroup-type-enum */
            type: UnlocodeGroupType;
            description?: string;
          } | null;
          tradeLaneDestination?: {
            id: string;
            name: string;
            /** @format unlocodegroup-type-enum */
            type: UnlocodeGroupType;
            description?: string;
          } | null;
          isFromAnAmendment?: boolean | null;
          isFuture?: boolean | null;
        })[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/contractAndAmendmentLegs`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Return a paginated list of locations for contract & amendment legs
   *
   * @tags Contract, Amendment
   * @name ContractAndAmendmentLegsLocationsList
   * @summary List available contract- & amendment leg locations
   * @request GET:/contracts/{contractUid}/contractAndAmendmentLegs/locations
   * @secure
   */
  contractAndAmendmentLegsLocationsList = (
    { contractUid, ...query }: ContractAndAmendmentLegsLocationsListParams,
    params: RequestParams = {},
  ) =>
    this.request<
      {
        total: number;
        count: number;
        page: number;
        limit: number;
      } & {
        result?: {
          filterValue?: string;
          /** @format location-type-enum */
          type?: LocationType;
          unlocode?: {
            unlocode: string;
            countryCode: string;
            placeCode: string;
            name: string;
            plainName: string;
            coordinates?: {
              long?: number;
              lat?: number;
            };
            subDivision: string;
            status: string;
            sourceUpdated: string;
            IATA: string;
            /** @format date-time */
            createdAt: Date;
            /** @format date-time */
            updatedAt: Date;
          } | null;
          unlocodeGroup?: {
            id: string;
            name: string;
            /** @format unlocodegroup-type-enum */
            type: UnlocodeGroupType;
            description?: string;
          } | null;
          /** @format terminus-type-enum */
          terminus?: TerminusType;
        }[];
      },
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/contractAndAmendmentLegs/locations`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update contract legs from a contract or amendment based on filter.
   *
   * @tags Contract
   * @name ContractAndAmendmentLegsFilteredUpdate
   * @summary Update contract legs
   * @request PUT:/contracts/{contractUid}/contractAndAmendmentLegs/filtered
   * @secure
   */
  contractAndAmendmentLegsFilteredUpdate = (
    { contractUid, ...query }: ContractAndAmendmentLegsFilteredUpdateParams,
    data: ContractAndAmendmentLegsFilteredUpdatePayload,
    params: RequestParams = {},
  ) =>
    this.request<
      void,
      {
        /** Error message */
        message?: string;
      }
    >({
      path: `/contracts/${contractUid}/contractAndAmendmentLegs/filtered`,
      method: "PUT",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
